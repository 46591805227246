import styled from 'styled-components';
import {Accordion, AccordionSummary, AccordionDetails} from "@material-ui/core"
import {FaChevronDown} from 'react-icons/fa';
import SectionWrapper from "../SectionWrapper"
import { queries } from "../../../breakpoints"

export const StyledWrapper = styled(SectionWrapper)`
    .MuiAccordion-root:before{
        display: none;
    }
`;

export const Headline = styled.h3`
    font-size: var(--fontSize-5);
    font-weight: bold;
    margin-bottom: 35px;
    @media(${queries.max_break_md}){
        display:none;
    }
`;

export const MobileHeadline = styled(Headline)`
    display:block;
    @media(${queries.min_break_md}){
        display:none;
    }
`;

export const StyledAccordion = styled(Accordion)`
    // Increase specificity with '&&&' to override styles from MUI
    &&& {
        box-shadow: none;
        background-color: transparent;
        .MuiAccordionDetails-root{
            padding:0
        }
    }
`;

export const StyledAccordionHeader = styled.h5`
    margin: 0;
    font-weight: bold;
`;

export const StyledAccordionDetails = styled(AccordionDetails)`
    &&& {
        margin: 0;
        padding: 0
    }
`;

export const StyledChevron = styled(FaChevronDown)`
    color: var(--color-primary);
`;

export const Panel = styled(AccordionSummary)`
    &&& {
        padding-left: 0;
    }
`;