import React from 'react';
import PropTypes from 'prop-types';
import { AccordionDetails } from "@material-ui/core"
import { StyledWrapper, Headline, StyledAccordion, StyledAccordionHeader, StyledAccordionDetails, StyledChevron, Panel, MobileHeadline } from "./FaqAccordionSection.styles"

const FaqAccordionSection = (props) => {
  return(
    <StyledWrapper backgroundColor={props.backgroundColor} style={props.style} sectionPadding={props.sectionPadding}>
      {!props.noHeadline && 
      <> 
      <Headline>Frequently Asked Questions</Headline>
      <MobileHeadline>FAQ's</MobileHeadline>
      </>}
      {props.faqs ? props.faqs.map((faq, i) => {
        return (
          <CustomAccordion header={faq.header} description={faq.description} key={i} index={i} />
        )
      }): ""} 
    </StyledWrapper>
  )
};

FaqAccordionSection.propTypes = {
  headline: PropTypes.string,
  noHeadline: PropTypes.bool,
  faqs: PropTypes.arrayOf(PropTypes.shape({
    header: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired
  })).isRequired,
  backgroundColor: PropTypes.string
};

FaqAccordionSection.defaultProps = {
  headline: null,
  faqs: null,
  noHeadline: false,
  backgroundColor: "#f6f6f6"
};

export default FaqAccordionSection;



//  ========== Helper Components ===========
const CustomAccordion = (props) => {
  return(
   <StyledAccordion>
    <Panel
      expandIcon={<StyledChevron/>}
      id={`accordion__panel${props.index}`}
    >
      <StyledAccordionHeader>{props.header}</StyledAccordionHeader>
    </Panel>
    <AccordionDetails>
      <StyledAccordionDetails dangerouslySetInnerHTML={{__html: props.description}}/>
    </AccordionDetails>
  </StyledAccordion>
)}