import CardsSection from "../components/sections/CardsSection/CardsSection"
import CtaSection from "../components/sections/CtaSection"
import FaqAccordionSection from "../components/sections/FaqAccordionSection"
import GeneralHeader from "../components/global/GeneralHeader"
import Layout from "../components/global/Layout"
import QuoteSection from "../components/sections/QuoteSection/QuoteSection"
import React from "react"
import ValuePropsSection from "../components/sections/ValuePropsSection"

const IndividualMeals = ({ location }) => {

  const valueProps = [
    {
      imageSrc:"https://res.cloudinary.com/grubhub-marketing/image/upload/v1652234223/HERMES/2022/B2B/GHCORP/B2B%20Corp%20Website/May%2011%20Handoff%20-%20NO%20HOUSE%20UPDATE/Individual%20Meals/EXPORT-IndividualMeals-ValueProp1.png",
      headline:"Offer equal benefits to team members",
      subheadline:"Offering individual meal perks is an easy way to foster inclusion in a hybrid work environment. Everyone gets the same meal budget and can order something they love, delivered to wherever they’re working."
    },
    {
      imageSrc:"https://res.cloudinary.com/grubhub-marketing/image/upload/v1652234223/HERMES/2022/B2B/GHCORP/B2B%20Corp%20Website/May%2011%20Handoff%20-%20NO%20HOUSE%20UPDATE/Individual%20Meals/EXPORT-IndividualMeals-ValueProp2.png",
      headline:"Satisfy every appetite",
      subheadline:"Grubhub serves nearly 90% of America with hundreds of thousands of restaurants. Count on us for fast, reliable and delicious food delivery that everyone on your team will love."
    },
    {
      imageSrc:"https://res.cloudinary.com/grubhub-marketing/image/upload/v1632933619/Grubhub%20Corporate%20Website/2021%20%E2%80%94%20Citrus/Illustrations/D-IndividualMeals/EXPORTS-Illustrations-IndividualMeals-3-Invoice-Citrus.png",
      headline:"Control your budget",
      subheadline:"Set ordering guidelines for your team. You choose when, where and how much they can order. Plus, say goodbye to expense reports with one monthly invoice for all food expenses."
    },
  ]

  const cards = [
    {
      title: "Catering",
      description: "Feed any occasion by preordering meals in advance or ordering on demand for same-day meetings.",
      link: "/catering/",
      imageSrc: "https://res.cloudinary.com/grubhub-marketing/image/upload/v1652231061/HERMES/2022/B2B/GHCORP/B2B%20Corp%20Website/May%2011%20Handoff%20-%20NO%20HOUSE%20UPDATE/Homepage/EXPORTS-CARD-Catering.jpg",
      linkText: "Learn more"
    },
    {
      title: "Group Order",
      description: "Send out restaurant choices and let your team order what they want. Or, let employees add food to a shared cart. Any way you order, meals are delivered together and everyone gets something they love.",
      link: "/group-orders/",
      imageSrc: "https://res.cloudinary.com/grubhub-marketing/image/upload/f_auto/v1626797393/Grubhub%20Corporate%20Website/2021/Photography%20Elements/_All%20Small%20Card%20Header%20Images/EXPORTS-CARD1-Images-GroupOrdering.jpg",
      linkText: "Learn more"
    }
  ]
  const faqs = [
    {
      header: "How do you have individual meals delivered?",
      description: `<span>Getting individual meals delivered is easy. With a <a href="/">Grubhub Corporate Account</a> employees and clients can order meals paid for by you to be delivered to their home, office, or wherever they are.</span>`
    },
    {
      header: "What do you serve at a lunch meeting?",
      description: `<span>At Grubhub, good lunches for work are any food that's quick and easy to grab on the go, like sandwiches, pizza or individually boxed salads. With a <a href="/">Grubhub Corporate Account</a> you can find good lunches for work that cater to every taste with the nation’s largest network of local restaurant partners.</span>`
    }
  ]

  return (
    <Layout
      location={location}
      title={"Individual Meals | Feed Remote Employees | Grubhub Corporate"}
      description={"Want to feed a team that's busy working at home or in the office? Individual employees can place their owner orders using their Grubhub Corporate Accounts."}
      className="individual-meals-page"
      utag_data={{
        pageGroup: "grubhub - lead generation",
        subGroup: "ways to order",
        pageName: "individual meal",
        brand: "grubhub"
      }}
      faqSeoList={faqs}
    >
      <GeneralHeader
        headline="Individual Meals"
        preheader="Ways to order"
        textColor="var(--color-secondary)"
        background="rgba(232,226,255,0.3)"
        ctaLink="/get-started/"
        ctaText="Get started"
        type="half-image"
        imageSrc="https://res.cloudinary.com/grubhub-marketing/image/upload/f_auto/v1626796711/Grubhub%20Corporate%20Website/2021/Header%20Images/Subpage%20Headers/HEADERS-SUBPAGES-2560x1707-IndividualMeals.jpg"
        alt="A salad on a desk with a female hand putting a fork in it to eat."
      />
      <ValuePropsSection
        headline="Get meals delivered to wherever your team sits"
        subheadline="Whether your employees are working remotely or in-office, they can use their meal benefits to get delicious food delivered right to their door. Employees can order daily, weekly, monthly or otherwise—whatever works for your team."
        valueProps={valueProps}
        layout="vertical"
      />
      <QuoteSection
        quote='"I can&apos;t tell you how much easier it is to set up an event or meeting and allow everyone to order their own meal and be able to manage their own dietary needs and customized orders."'
        author="Elena Hunsaker"
        position="Administrative Assistant, GEHA"
        imageSrc="https://res.cloudinary.com/grubhub-marketing/image/upload/v1649354131/HERMES/2022/B2B/GHCORP/B2B%20Corp%20Website/March%208%20Handoff/Individual%20Meals/PARTNERLOGOS-Geha.png"
        alt="Geha logo"
        backgroundColor="rgba(232,226,255,0.15)"
      />
      <CardsSection headline="More ways to order" cards={cards} isSliderOnMobile/>
      <CtaSection headline="Discover the industry leader in flexible meal benefits" backgroundColor="#F9F9F9"/>
      <FaqAccordionSection faqs={faqs}   backgroundColor="white"/>
    </Layout>
  )
}

export default IndividualMeals
